import { useEffect } from "react";
import { executeHdmiCommand, HdmiCommand } from './hdmiExecutor';

export interface EventData {
    action: string;
    message: string;
    details?: Record<string, any>;
    command?: HdmiCommand;
}

const API_URL = process.env.REACT_APP_API_URL;

export function establishSSEConnection(
    companyId: string,
    tvId: number,
    handleNewEvent: (data: EventData) => void
): (() => void) | null {
    if (!companyId || !tvId) return null;

    const eventSource = new EventSource(`${API_URL}/ad/events?companyId=${companyId}&tvId=${tvId}`);

    eventSource.onmessage = async (event) => {
        const data: EventData = JSON.parse(event.data);

        if (data.action === 'HDMI_CONTROL' && data.command) {
            try {
                await executeHdmiCommand(data.command);
                console.log(`HDMI command executed: ${data.command}`);
            } catch (error) {
                console.error('Failed to execute HDMI command:', error);
            }
        }

        handleNewEvent(data);
    };

    eventSource.onerror = (error) => {
        console.error('SSE connection error:', error);
    };

    return () => {
        eventSource.close();
    };
}

export const useSSE = (companyId: string, tvId: number, handleNewEvent: (eventData: EventData) => void) => {
    useEffect(() => {
        const closeSSEConnection = establishSSEConnection(companyId, tvId, handleNewEvent);

        return () => {
            if (closeSSEConnection) {
                closeSSEConnection();
            }
        };
    }, [companyId, tvId, handleNewEvent]);
};
