import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { fetchAds } from '../services/s3-service';
import { EventData, establishSSEConnection } from '../helpers/sse';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

let SPLIT_SCREEN_COUNT = 0;

interface Ad {
    mediaLink: string;
    startDate?: string;
    endDate?: string;
    recurrenceType?: string;
}

interface SlideProps {
    key: string;
    url: string;
    isSelected?: boolean;
    onVideoEnd: () => void;
}

export interface IProps {
    companyId: string | undefined;
    tvId: number | undefined;
}

const Slide: React.FC<SlideProps> = ({ url, isSelected, onVideoEnd }) => {
    const playerRef = useRef<ReactPlayer>(null);

    useEffect(() => {
        // This makes sure the video starts playing when it's selected
        if (isSelected) {
            playerRef.current?.seekTo(0);
        }
    }, [isSelected]);

    return (
        <div className="carousel-video" style={{ position: 'relative', width: '100%', height: '100vh' }}>
            <ReactPlayer
                ref={playerRef}
                loop={false}
                muted={true}
                onEnded={onVideoEnd}
                playing={isSelected}
                controls={false}
                url={url}
                width="100%"
                height="100%"
                style={{ objectFit: 'cover' }}
            />
        </div>
    );
};

const Play: React.FC = () => {
    const location = useLocation();
    const [currentSlide, setCurrentSlide] = useState(0);
    const [filteredAds, setFilteredAds] = useState<Ad[]>([]);
    const { companyId, tvId } = location.state as { companyId: string; tvId: number };
    const imageTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const fetchFiles = useCallback(async () => {
        if (!companyId || !tvId) return;
        console.log("Fetching ads for TVID: ", tvId);
        const { ads: fetchedAds, splitScreenOption } = await fetchAds(+companyId, +tvId);
        shuffleArray(fetchedAds);
        SPLIT_SCREEN_COUNT = splitScreenOption; // Update split screen option
        filterActiveAds(fetchedAds); // Filter active ads based on current date    
        // Reset the slide to the first slide
        setCurrentSlide(0);
    }, [companyId, tvId]);

    const handleNewEvent = useCallback((eventData: EventData) => {
        console.log("Received SSE event: ", eventData.action);

        if (eventData.action === 'HDMI_CONTROL' && eventData.command) {
            // HDMI command is already handled in SSE helper
            console.log('Received HDMI command:', eventData.command);
        } else if (eventData.action === 'UploadMedia' || eventData.action === 'UpdateSplitScreen' || eventData.action === 'RemoveMedia') {
            fetchFiles();
        }
    }, [fetchFiles]);

    useEffect(() => {
        fetchFiles();

        const closeSSEConnection = companyId && tvId
            ? establishSSEConnection(companyId, tvId, handleNewEvent)
            : null;

        return () => {
            if (closeSSEConnection) closeSSEConnection();
        };
    }, [companyId, tvId, fetchFiles, handleNewEvent]);

    const filterActiveAds = (allAds: Ad[]) => {
        const now = dayjs().utc();

        const activeAds = allAds.filter(ad => {
            if (ad.startDate && ad.endDate) {
                const start = dayjs(ad.startDate).utc();
                const end = dayjs(ad.endDate).utc();
                return now.isBetween(start, end, null, '[]');
            }
            return true;
        });

        setFilteredAds(activeAds);
    };

    const isVideo = (url: string) => {
        const videoFileRegex = /\.(mp4|webm|ogg|mov|avi|mkv)$/i;
        return videoFileRegex.test(url);
    };

    const shuffleArray = (array: any[]) => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    };

    const handleVideoEnd = () => {
        if (filteredAds.length > 0) {
            setCurrentSlide(prevSlide => (prevSlide + 1) % filteredAds.length);
        }
    };

    const startImageTimer = useCallback(() => {
        // Set a timeout to move to the next slide after 7500ms (for images)
        imageTimeoutRef.current = setTimeout(() => {
            setCurrentSlide(prevSlide => (prevSlide + 1) % filteredAds.length);
        }, 7500);
    }, [filteredAds.length]);

    useEffect(() => {
        if (imageTimeoutRef.current) {
            clearTimeout(imageTimeoutRef.current);
            imageTimeoutRef.current = null;
        }

        // Start the timer only for images, not videos
        if (filteredAds.length > 0 && !isVideo(filteredAds[currentSlide].mediaLink)) {
            startImageTimer();
        }

        // Cleanup function to clear the timer on unmount or dependency change
        return () => {
            if (imageTimeoutRef.current) {
                clearTimeout(imageTimeoutRef.current);
            }
        };
    }, [currentSlide, filteredAds, startImageTimer]);

    const customRenderItem = (item: React.ReactNode, options?: { isSelected: boolean }) => {
        if (React.isValidElement(item)) {
            const { type, props } = item;
            const mergedProps = { ...props, ...options, onVideoEnd: handleVideoEnd };
            return React.createElement(type, mergedProps);
        } else {
            return null;
        }
    };

    const renderSplitScreen = () => {
        const splitAds = filteredAds.slice(0, SPLIT_SCREEN_COUNT);
        const splitCount = Math.ceil(Math.sqrt(SPLIT_SCREEN_COUNT));

        return (
            <div style={{ display: 'grid', gridTemplateColumns: `repeat(${splitCount}, 1fr)`, height: '100vh' }}>
                {splitAds.map((ad, index) => (
                    isVideo(ad.mediaLink) ?
                        <div key={`video-slide-${index}`} style={{ position: 'relative', width: '100%', height: '100%' }}>
                            <ReactPlayer
                                url={ad.mediaLink}
                                playing={true}
                                controls={false}
                                muted={true}
                                loop={true}
                                width="100%"
                                height="100%"
                                style={{ objectFit: 'cover' }}
                            />
                        </div>
                        :
                        <div key={`image-${index}`} style={{ position: 'relative', width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <img
                                alt={`Media ${index}`}
                                src={ad.mediaLink}
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        </div>
                ))}
            </div>
        );
    };

    return (
        <>
            {filteredAds.length > 0 &&
                (SPLIT_SCREEN_COUNT === 0 ? (
                    <Carousel
                        selectedItem={currentSlide}
                        renderItem={customRenderItem}
                        showStatus={false}
                        showIndicators={false}
                        showArrows={false}
                        showThumbs={false}
                    >
                        {filteredAds.map((ad, index) => (
                            isVideo(ad.mediaLink) ? (
                                <Slide
                                    key={`video-slide-${index}`}
                                    url={ad.mediaLink}
                                    onVideoEnd={handleVideoEnd}
                                />
                            ) : (
                                <div key={`image-${index}`} className="carousel-image">
                                    <img
                                        alt={`Media ${index}`}
                                        src={ad.mediaLink}
                                    />
                                </div>
                            )
                        ))}
                    </Carousel>
                ) : (
                    renderSplitScreen()
                ))
            }
        </>
    );
};

export default Play;
